import React from 'react'
import { graphql } from 'gatsby'
import { Container, Box } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import GatsbyConfig from '../../../gatsby-config';
import ContactForm from '@widgets/ContactForm'
import ContentText from '@solid-ui-components/ContentText'

const ContactPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo
        title='Contact Us'
        description={``}
        siteUrl={GatsbyConfig.siteMetadata.siteUrl}
        thumbnail={{
          hero: {
            src: '/srm_acronym_logo.png'
          }
        }}
      />
      {/* Blocks */}
      <Header />
      <Divider space='5' />
      <Container>
        <Box
          sx={{
            textAlign: [`left`],
            maxWidth: '300px'
          }}
        >
          <ContentText
              content={[{
                color: "omegaDarker",
                text: "Contact Us",
                variant: "h1",
              },{
                color: "omegaDarker",
                text: "How can we help?",
                variant: "medium",
              }]}
              mb='1'
              sx={{ marginTop: [`30px`, `0`, `0`, `0`] }}
          />
        </Box>
        <Divider />
        <ContactForm />
      </Container>
      <Divider />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query contactBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/contact"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default ContactPage
